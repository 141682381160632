import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../images/logo.jpg";

const NavBar = () => {
  const [active, setActive] = useState(false);
  const body = document.querySelector("body");

  const onClick = (e) => {
    setActive(!active);
  };

  const onClickLink = (e) => {
    setActive(false);
  };

  if (active) {
    body.style.cssText = "overflow:hidden;";
  } else {
    body.style.cssText = "overflow:none;";
  }
  return (
    <>
      <Nav>
        <div className="logoDiv">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>

        <ul className={`${active ? "activenav" : ""}`}>
          <li>
            <Link onClick={onClickLink} to="/">
              Home
            </Link>
          </li>

          <li>
            <Link onClick={onClickLink} to="/team">
              Team
            </Link>
          </li>

          <li>
            <Link to="/links" onClick={onClickLink}>
              Quick Links
            </Link>
          </li>

          <li>
            <Link to="/youtube" onClick={onClickLink}>
              Youtube
            </Link>
          </li>
        </ul>

        <div className="toggle" onClick={onClick}>
          <div className="toggle-line"></div>
          <div className="toggle-line"></div>
          <div className="toggle-line"></div>
        </div>
      </Nav>
    </>
  );
};

const Nav = styled.div`
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 4%;

  .toggle {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-around;
  }

  li {
    list-style: none;
    margin: 0 0 0 4rem;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: rgb(61, 111, 150);

    img {
      max-width: 70%;
    }
  }

  /* nav responsiveness */
  @media screen and (max-width: 900px) {
    .logoDiv {
      order: 1;
      z-index: 10;
    }

    .toggle {
      display: block;
      cursor: pointer;
      order: 2;
      z-index: 10;
    }

    .toggle-line {
      width: 25px;
      height: 3px;
      background-color: rgb(44, 100, 200);
      margin: 5px;
    }

    ul {
      order: 3;
      width: 100%;
      display: block;
      position: fixed;
      z-index: 4;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow-x: hidden;
      background-color: #f1f1f1;
      transform: translateY(-100vh);
      transition: all ease-out 0.2s;

      li {
        margin: 0 0 2rem;
        opacity: 0;
        transform: translateY(10vh);
      }
    }

    ul.activenav {
      transform: translateX(0);

      li {
        margin: 0 0 2rem;
        opacity: 1;
        transform: translateX(0);
        transition: all ease-out 0.8s;
      }
    }
  }
`;

export default NavBar;
