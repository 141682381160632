import React from "react";
import Logo from "../images/logo.jpg";
import styled from "styled-components";

const team = () => {
   // eslint-disable-next-line
  const team = [
    {
      name: "Niles Jay",
      position: "Head Consultant",
      image: "../images/logo.jpg",
    },
    {
      name: "Niles Jay",
      position: "Head Consultant",
      image: "../images/logo.jpg",
    },
    {
      name: "Niles Jay",
      position: "Head Consultant",
      image: "../images/logo.jpg",
    },
    {
      name: "Niles Jay",
      position: "Head Consultant",
      image: "../images/logo.jpg",
    },
    {
      name: "Niles Jay",
      position: "Head Consultant",
      image: "../images/logo.jpg",
    },
    {
      name: "Niles Jay",
      position: "Head Consultant",
      image: "../images/logo.jpg",
    },
    {
      name: "Niles Jay",
      position: "Head Consultant",
      image: "../images/logo.jpg",
    },
    {
      name: "Niles Jay",
      position: "Head Consultant",
      image: "../images/logo.jpg",
    },
  ];

  return (
    <Member>
      <div>
        <h1>Our Team!</h1>
      </div>
      <div className="teamList">
        <div className="teamInfo">
          <img src={Logo} alt="member" />
          <h2>CEO</h2>
          <h6>Mauritius</h6>
        </div>

        <div className="teamInfo">
          <img src={Logo} alt="member" />
          <h2>Collins</h2>
          <h6>Mauritius</h6>
        </div>
        <div className="teamInfo">
          <img src={Logo} alt="member" />
          <h2>James</h2>
          <h6>Nigeria</h6>
        </div>
        <div className="teamInfo">
          <img src={Logo} alt="member" />
          <h2>Josiah</h2>
          <h6>Nigeria</h6>
        </div>
        <div className="teamInfo">
          <img src={Logo} alt="member" />
          <h2>Kelvin</h2>
          <h6>Nigeria</h6>
        </div>
        <div className="teamInfo">
          <img src={Logo} alt="member" />
          <h2>Niles Jay</h2>
          <h6>Okitipupa</h6>
        </div>
      </div>
    </Member>
  );
};

const Member = styled.div`
  width: 100%;
  text-align: center;
  color: rgb(44, 100, 200);
  font-weight: normal;

  .teamList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 4rem;

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
      row-gap: 4rem;
    }
  }

  h1 {
    font-weight: 300;
    margin-bottom: 3rem;
  }

  h2 {
    color: rgb(61, 111, 150);
  }

  h6 {
    font-weight: 400;
    font-size: 0.9rem;
    color: rgb(61, 111, 150);
  }

  img {
    height: 110px;
    width: 110px;
    border-radius: 100%;
  }
`;

export default team;
