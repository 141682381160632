import { useState, useEffect } from 'react'
import "./App.css";
import Nav from "./components/navBar.js";
import Footer from "./components/footer.js";
import Home from "./components/home.js";
import Form from "./components/form.js";
import Links from "./components/links.js";
import Appointment from "./components/appointment.js";
import Team from "./components/team.js";
import Youtube from "./components/youtube.js";
import Payments from "./components/payments";
import { Switch, Route } from "react-router-dom";
import { StateProvider } from "./context";
import { useLocation } from "react-router-dom";

function App() {
  const path = useLocation().pathname;

  const [home, setHome] = useState(false);

  useEffect(() => {
    console.log(path);
    if (path === '/') {
      setHome(true);
    } else {
      setHome(false);
    }
    console.log(path);
  }, [path]);

  return (
    <StateProvider>
      <div className={home ? "App Homepage" : "App"}>
        <Nav />
        <div className="container">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/form">
              <Form />
            </Route>
            <Route exact path="/links">
              <Links />
            </Route>
            <Route exact path="/appointment">
              <Appointment />
            </Route>
             <Route exact path="/team">
              <Team />
            </Route>
            <Route exact path="/payments">
              <Payments />
            </Route>
            <Route exact path="/youtube">
              <Youtube />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </StateProvider>

  );
}

export default App;
