import { createContext, useContext, useReducer } from "react";
import { reducer } from "./reducer";

const Context = createContext();

export const StateProvider = (props) => {
  const defaultState = {
    message: null,
    reference: null,
    status: null,
    trans: null,
    transaction: null,
    trxref: null,
  };

  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <Context.Provider value={[state, dispatch]}>
      {props.children}
    </Context.Provider>
  );
};

export const useGlobalState = () => useContext(Context);
