import firebase from 'firebase';
import 'firebase/firestore';

  var firebaseConfig = {
    apiKey: "AIzaSyBWLcs3rQfwUmYq01ikt2iaecwwYbuSIs0",
    authDomain: "aocc-6d57f.firebaseapp.com",
    projectId: "aocc-6d57f",
    storageBucket: "aocc-6d57f.appspot.com",
    messagingSenderId: "39477671648",
    appId: "1:39477671648:web:c45731aad7c0e1af1b0285",
    measurementId: "G-5Z842W24GD"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  const db = firebase.firestore();

  export {db}
