import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom'

const Youtube = () => {
    const [ytData, setYtData] = useState([])
//     curl \
//   'https://youtube.googleapis.com/youtube/v3/search?channelId=jjjuu&order=date&key=[YOUR_API_KEY]' \
//   --header 'Authorization: Bearer [YOUR_ACCESS_TOKEN]' \
//   --header 'Accept: application/json' \
//   --compressed


    useEffect(() => {
        const key = "AIzaSyDNs1AXW1bCsdZl45arqoFymR_88e3vSzw";
        const id = "UCXgGY0wkgOzynnHvSEVmE3A"

        fetch(`https://youtube.googleapis.com/youtube/v3/search?channelId=${id}&order=date&maxResults=10&key=${key}`)
        .then((response) => {
         return response.json()
        })
        .then((data) => {
            console.log(data)
            console.log(data.items[0].id.videoId);
            const resultyt = data.items.map(obj => 
               `http://www.youtube.com/embed/${obj.id.videoId}`
            );
            setYtData(resultyt)
            })
    
  }, []);
    return (
        <YtStyled>
       { ytData.map((link, i) => (
            <div style={{ width: "100%", height: "40%" }}  key={i}>
             <iframe title="aoc" style={{ width: "100%", height: "370px" }}  src={link} frameBorder="10" allowFullScreen></iframe>
             </div>
        ))}
         <div className="button">
          <Link to="/form">
            <button>Continue</button>
          </Link>
        </div>
         
        </YtStyled>
    
    )
}
const YtStyled = styled.div `
text-align: center;
.button {
    margin-top: 1.5rem;

    a {
      text-decoration: none;
    }

    button {
      padding: 1rem 1.5rem;
      width: 35%;
      background-color: rgb(44, 100, 200);
      font-size: 1rem;
      color: #f1f1f1;
      text-align: center;
      border: none;
    }
  }
`
export default Youtube;
