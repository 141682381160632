import React from "react";
import styled from "styled-components";
const footer = () => {
  return (
    <Foot>
      <p>©2021 AOC Schengen</p>
    </Foot>
  );
};

const Foot = styled.footer`
  text-align: center;
  color: rgb(61, 111, 150);
  font-weight: 300;
  font-size: 1rem;
  padding: 1rem 0;

  p {
    display: grid;
    place-items: center;
    margin: 0;
  }
`;

export default footer;
