import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const home = () => {
  return (
    <Home>
      <div className="homeText">
        <h2>
          <span>
            “It's not <b>about</b> making the{" "}
          </span>
          <b>right choice.</b>{" "}
          <span>
            It's about making <b>a choice</b> and making it right.”
          </span>
          <br />
        </h2>
        <div className="button">
          <Link to="/links">
            <button>Get Started Now</button>
          </Link>
        </div>
      </div>
    </Home>
  );
};

const Home = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .homeText {
    font-size: 1.8rem;
    width: 60%;
    color: #FFFFFF;
    align-items: center;

    h2 {
      span {
        font-weight: normal;
      }

      b {
        font-weight: bold;
      }
    }
  }

  .button {
    margin-top: 1.5rem;

    a {
      text-decoration: none;
    }

    button {
      padding: 1rem 1.5rem;
      width: 35%;
      background-color: rgb(44, 100, 200);
      font-size: 1rem;
      color: #f1f1f1;
      text-align: center;
      border: none;
    }
  }

  /* responsiveness */
  @media screen and (max-width: 900px) {
    .homeText {
      font-size: 2rem;
      width: 80%;
    }
  }

  @media screen and (max-width: 768px) {
    .homeText {
      font-size: 1.7rem;
    }
  }

  @media screen and (max-width: 600px) {
    .homeText {
      width: 100%;
      padding: 2%;

      h2 {
        font-size: 1.6rem;
      }
    }

    .button {
      button {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .homeText {
      font-size: 1.2rem;
    }
  }
`;

export default home;
