import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

export default function App() {
  const [alert, setALert] = useState(false);
  const [error, setError] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    amount: "",
  });
  const history = useHistory()
  const config = {
    public_key: "FLWPUBK_TEST-0e5471deba7e68c4da51c8a779371b99-X",
    tx_ref: Date.now(),
    amount: details.amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: details.email,
      name: details.name,
    },
    customizations: {
      title: "AOC Consults",
      description: "Services Payments",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const onSubmit = (e) => {
    e.preventDefault();

    handleFlutterPayment({
      callback: (response) => {
        closePaymentModal();

        if (response.status === "successful") {
          setALert(true);
          setDetails({
            name: "",
            email: "",
            amount: "",
          });
          history.push('/appointment')
        } else {
          setError(true);
          setDetails({
            name: "",
            email: "",
            amount: "",
          });
        }
      },
      onClose: () => {},
    });
  };

  const onChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <React.Fragment>
      <FormStyled>
        <div className="formstyle">
          <div className="title">
            <h4>Service Payments</h4>
            <p>Please enter your name and email and amount you want to pay.</p>
          </div>

          <form onSubmit={onSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Full Name*"
              value={details.name}
              onChange={onChange}
              required
            />

            <input
              type="email"
              name="email"
              placeholder="Your E-mail*"
              value={details.email}
              onChange={onChange}
              required
            />

            <input
              type="number"
              name="amount"
              placeholder="Amount (NGN)"
              value={details.amount}
              onChange={onChange}
              required
            />

            <button type="submit">Make Payment</button>
          </form>
        </div>
      </FormStyled>
      {alert && (
        <Alert>
          <div className="inner">
            <div className="close" onClick={() => setALert(false)}>
              <i class="fas fa-times"></i>
            </div>
            <div className="icon">
              <i class="far fa-check-circle"></i>
            </div>
            <h3 className="title">Payment Successful</h3>
            <p className="details">
              Your payment have been conirmed, please check your email for
              receipts.
            </p>
          </div>
        </Alert>
      )}

      {error && (
        <Alert>
          <div className="inner">
            <div className="close" onClick={() => setError(false)}>
              <i className="fas fa-times"></i>
            </div>
            <div className="icon error">
              <i className="fas fa-exclamation-circle"></i>
            </div>
            <h3 className="title">An Error Occured</h3>
            <p className="details">
              An error occured while processing your payment, please check your
              network and try again
            </p>
          </div>
        </Alert>
      )}
    </React.Fragment>
  );
}

const FormStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .formstyle {
    width: 80%;
  }

  .title {
    margin: 1rem 0 2rem;
    h4 {
      font-weight: 300;
      font-size: 2rem;
      font-weight: 500;
      color: rgb(61, 111, 150);
      width: 100%;
    }

    p {
      color: rgb(61, 111, 150);
      margin: 0.5rem 0;
    }
  }

  input,
  select {
    display: block;
    width: 100%;
    padding: 1rem;
    outline: none;
    margin-bottom: 1rem;
    border: 1px solid rgba(61, 111, 150, 0.3);
    appearance: none;

    &::placeholder {
      color: rgb(61, 111, 140);
    }
  }

  button {
    padding: 1rem 3rem;
    width: 100%;
    background-color: rgb(44, 100, 200);
    font-size: 1.2rem;
    color: #f1f1f1;
    display: block;
    border: none;
  }

  /* responsiveness */

  @media screen and (max-width: 768px) {
    .formstyle {
      width: 100%;
    }

    .title {
      h4 {
        font-size: 1.4rem;
      }

      p {
        font-weight: 200;
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (max-width: 500px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 450px) {
    h4 {
      font-size: 1.2rem;
    }
  }
`;

const Alert = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(44, 100, 200, 0.85);

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1.5rem;
    background-color: #ffffff;
    border-radius: 8px;
    width: 400px;
    position: relative;

    @media screen and (max-width: 500px) {
      width: 90%;
    }
  }

  .close {
    padding-left: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;

    i {
      font-size: 1.3rem;
      opacity: 0.7;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 4.5rem;
      color: #52b75f;
    }
  }

  .icon.error i {
    color: #dd3d45;
  }

  .title {
    font-weight: 300;
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(61, 111, 150);
    text-align: center;
    margin-top: 1rem;
  }

  .details {
    color: #333333;
    margin: 0.5rem 0;
    text-align: center;
    font-size: 1rem;
    opacity: 0.8;
  }
`;
