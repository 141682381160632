import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {  } from "react-router-dom";
// import { usePaystackPayment } from "react-paystack";
// import { useGlobalState } from "../context";

// const config = {
//   reference: new Date().getTime(),
//   email: "egbaidomehjamez@example.com",
//   amount: 20000,
//   publicKey: process.env.REACT_APP_PAYSTACK_KEY
// };

const Clicks = () => {
  // const OnClick = () => {
  //             // initializePayment(OnSuccss, eonClose);
  //             const history = useHistory();
  //             history.push("/appointment");
  //           }
  // const [, dispatch] = useGlobalState();
  // const initializePayment = usePaystackPayment(config);
  

  // const OnSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // dispatch({ type: "SET_STATE", payload: reference });
    // history.push("/appointment");
  // };

  // const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");


  return (
    <Click>
      {/* <div className="textdiv">
          <h1>
            Congratulations on making the first step to achieving your goals!{" "}
            <br /> You Will Be Speaking With <b>ADEDAMOLA OLOKETUYI</b>
          </h1>
        </div> */}
      <div className="buttons">
        <h2>QUICK LINKS</h2>
        <Link to="/payments">
          <button
            // onClick={OnClick}
          >
            Book A Paid Session
          </button>
        </Link>
        <Link to="/payments">
          <button>Appointments</button>
        </Link>
        <Link to="#">
          <button>AOC Seminar</button>
        </Link>
      </div>
    </Click>
  );
};


const Click = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .textdiv {
    margin: 1.3rem;

    h1 {
      font-size: 1.7rem;
      color: rgb(61, 111, 150);
      font-weight: 400;
      display: inline;
    }
  }

  .buttons {
    h2 {
      font-size: 1.5rem;
      color: rgb(61, 111, 150);
      font-weight: 600;
      margin-bottom: 2rem;
    }

    button {
      padding: 1rem 3rem;
      width: 80%;
      font-size: 1.5rem;
      font-weight: 300;
      background-color: transparent;
      border-color: rgb(61, 111, 150);
      color: rgb(61, 111, 150);
      border: 1px solid rgba(61, 111, 150, 0.3);
      margin-bottom: 1.5rem;

      &:hover {
        transition: ease-in-out 0.5s;
        box-shadow: inset 0 0 0 5em rgb(44, 100, 200);
        color: #f1f1f1;
      }
    }
  }

  /* responsiveness */
  @media screen and (max-width: 600px) {
    .buttons {
      h2 {
        font-size: 1.3rem;
      }

      button {
        width: 100%;
        font-size: 1.2rem;
      }
    }
  }
`;

export default Clicks;
