import React, {  useEffect } from "react";
import styled from "styled-components";

const Appointment = () => {

  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {/* {loading && <LoadingStyled />} */}
      <AppointmentStyled>
        <div className="textdiv">
          <h1>
            {" "}
            <b> YOU ARE A STEP CLOSER! </b>{" "}
          </h1>
          <p>
            Do call on the <b> Time And Date </b> selected on the calendar below{" "}
          </p>
          <h2>
            You Will Be Speaking With <b>ADEDAMOLA OLOKETUYI</b> of AOC
          </h2>
        </div>

        <div className="calendly-container">
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/egbaidomeh/30min"
            style={{ width: "100%", height: "100%" }}
          ></div>
        </div>
      </AppointmentStyled>
    </React.Fragment>
  );
};

// const LoadingStyled = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   width: 100vw;
//   height: 100vh;
// `;

const AppointmentStyled = styled.div`
  text-align: center;
  color: rgb(61, 111, 150);

  .textdiv {
    margin: 1.3rem;
  }

  h1 {
    font-weight: 400;
    margin: 0.4rem;
  }

  p,
  h2 {
    font-weight: 300;
    margin: 0.5rem;
  }

  .calendly-container {
    height: 120vh;
  }

  /* responsiveness */
  @media screen and (max-width: 900px) {
    .textdiv {
      margin: 1.3rem;
    }
    h1 {
      font-weight: 400;
      margin: 0.4rem;
    }
    p,
    h2 {
      font-weight: 300;
      margin: 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-weight: 400;
      margin: 0.3rem;
    }
    p,
    h2 {
      font-weight: 300;
      margin: 0.3rem;
    }
  }

  @media screen and (max-width: 600px) {
    .textdiv {
      margin: 1rem 0 2rem;
    }

    h1 {
      font-weight: 400;
      font-size: 0.8rem;
      margin: 0.3rem;
    }
    p,
    h2 {
      font-weight: 300;
      margin: 0.3rem;
      font-size: 1rem;
    }
  }
`;

export default Appointment;
