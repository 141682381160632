import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {db} from './firebase.js'

const Form = () => {
  // user data storage
  const [user, setUser] = useState({
    name: "",
    email: "",
    number: "",
    location: "",
    qualifications: "",
  });

  const { name, email, number, location, qualifications } = user;

  const onChange = (e) => {
    e.preventDefault();
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
         db.collection("Users").add(user);
    setUser({
      name: "",
      email: "",
      number: "",
      location: "",
      qualifications: "",
    });
    history.push("/links");
  };
  return (
    <FormStyled>
      <div className="formstyle">
        <div className="title">
          <h4>Book A Session With AOC.</h4>
          <p>
            Enter your details, proceed to payment and select a date for your
            session
          </p>
        </div>

        <form onSubmit={onSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name*"
            value={name}
            onChange={onChange}
            required
          />

          <input
            type="email"
            name="email"
            placeholder="Your E-mail*"
            value={email}
            onChange={onChange}
            required
          />

          <input
            type="tel"
            name="number"
            placeholder="Your Phone Number*"
            value={number}
            onChange={onChange}
            required
          />

          <input
            type="address"
            name="location"
            placeholder="Your Address*"
            value={location}
            onChange={onChange}
            required
          />

          <select
            name="qualifications"
            value={qualifications}
            onChange={onChange}
            required
          >
            <option value="">Choose Your Degree</option>
            <option value="B.sc">B.sc</option>
            <option value="M.sc">M.sc</option>
            <option value="PhD">PhD</option>
          </select>
          <button type="submit">Continue</button>
        </form>
      </div>
    </FormStyled>
  );
};

const FormStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .formstyle {
    width: 80%;
  }

  .title {
    margin: 1rem 0 2rem;
    h4 {
      font-weight: 300;
      font-size: 2rem;
      font-weight: 500;
      color: rgb(61, 111, 150);
      width: 100%;
    }

    p {
      color: rgb(61, 111, 150);
      margin: 0.5rem 0;
    }
  }

  input,
  select {
    display: block;
    width: 100%;
    padding: 1rem;
    outline: none;
    margin-bottom: 1rem;
    border: 1px solid rgba(61, 111, 150, 0.3);
    appearance: none;

    &::placeholder {
      color: rgb(61, 111, 140);
    }
  }

  button {
    padding: 1rem 3rem;
    width: 100%;
    background-color: rgb(44, 100, 200);
    font-size: 1.2rem;
    color: #f1f1f1;
    display: block;
    border: none;
  }

  /* responsiveness */

  @media screen and (max-width: 768px) {
    .formstyle {
      width: 100%;
    }

    .title {
      h4 {
        font-size: 1.4rem;
      }

      p {
        font-weight: 200;
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (max-width: 500px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 450px) {
    h4 {
      font-size: 1.2rem;
    }
  }
`;

export default Form;
